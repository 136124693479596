import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import {
  ActionIcon,
  Group,
  Grid,
  Flex,
  Text,
  HoverCard,
  Divider,
  Modal,
  Box,
  Select,
  List,
  Button,
} from "@mantine/core";
import { IconBolt, IconPlus, IconTrash } from "@tabler/icons-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export default function SelectItemManagement({ field, effortId }) {
  const [isOpen, setOpen] = useState(false);
  const [triggers, setTriggers] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    fetchTriggers();
  }, [isOpen]);

  if (!field) return null;

  function fetchTriggers() {
    axios
      .post(`/efforts/${effortId}/data-grabber/`, {
        context: "engagement_item_triggers",
      })
      .then(({ data }) => {
        setTriggers(data.response);
      })
      .catch((err) => {
        setTriggers([]);
      });
  }

  function onClose() {
    setOpen(false);
  }

  return (
    <div>
      <ActionIcon
        onClick={() => setOpen(true)}
        title="Add trigger"
        variant="light"
        color="green"
      >
        <IconBolt size={16} />
      </ActionIcon>
      <Modal opened={isOpen} onClose={onClose} title={field.label}>
        {field.options.map((m, i) => (
          <div key={i}>
            <SelectItem
              effortId={effortId}
              item={m}
              triggers={triggers.filter((f) => f.select_item_id === m.id)}
              fetchData={() => fetchTriggers()}
            />
            {i < field.options.length - 1 && <Divider mt="lg" mb="lg" />}
          </div>
        ))}
      </Modal>
    </div>
  );
}

function SelectItem({ item, effortId, fetchData, triggers }) {
  const [templateId, setTemplateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [triggerOpen, setTriggerOpen] = useState(false);
  const [postmarkTemplateNames, setPostmarkTemplateNames] = useState([]);
  const queryClient = useQueryClient();
  const fetchEiWithTrigger = () =>
    queryClient.invalidateQueries([`eiWithTrigger${effortId}`]);

  useEffect(() => {
    setTemplateId("");
    if (!triggerOpen) return;
    fetchTemplates();
  }, [triggerOpen]);

  if (!item) return null;

  function fetchTemplates() {
    axios
      .post(`/efforts/${effortId}/data-grabber/`, {
        context: "conditional_reg_templates",
      })
      .then(({ data }) => {
        setPostmarkTemplateNames(data.response);
      })
      .catch((err) => {
        setPostmarkTemplateNames([]);
      });
  }

  function onSubmit() {
    const req = {
      select_item_id: item.id,
      email_template: templateId,
      variety: "email_registration",
    };

    axios
      .post(`/triggers/`, req)
      .then(() => {
        setLoading(false);
        setTriggerOpen(false);
        toast.success("Trigger created!");
        fetchEiWithTrigger();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const templateOptions = postmarkTemplateNames.map((m) => ({
    label: m,
    value: m,
  }));

  return (
    <div>
      <Text fw={600}>{item.label}</Text>
      {!triggerOpen && (
        <Box mt="sm" mb="lg">
          {triggers.map((t, i) => (
            <TriggerItem
              key={i}
              trigger={{
                ...t,
                option_label: item.label,
              }}
              fetchData={fetchData}
              onRemoveSuccess={() => {
                fetchEiWithTrigger();
              }}
            />
          ))}
        </Box>
      )}
      {!triggerOpen && triggers.length === 0 && (
        <Flex gap="sm" mt="sm">
          <Button
            size="xs"
            variant="light"
            radius="xl"
            color="gray"
            leftSection={<IconPlus size={16} />}
            onClick={() => setTriggerOpen(!triggerOpen)}
          >
            Trigger
          </Button>
        </Flex>
      )}
      {triggerOpen && (
        <Box mt="sm">
          <Select
            label="Email template to use"
            data={templateOptions}
            value={templateId}
            onChange={(e) => setTemplateId(e)}
            required
            placeholder="Select one..."
            searchable
          />
          <Button
            fullWidth
            mt="sm"
            disabled={!templateId}
            onClick={onSubmit}
            loading={loading}
          >
            Create trigger
          </Button>
          <Flex justify="center" mt="sm">
            <Button
              variant="subtle"
              color="gray"
              size="xs"
              onClick={() => setTriggerOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="subtle"
              color="gray"
              size="xs"
              onClick={() => fetchTemplates()}
            >
              Refresh templates
            </Button>
          </Flex>
        </Box>
      )}
    </div>
  );
}

function TriggerItem({ trigger, fetchData, onRemoveSuccess }) {
  const [loading, setLoading] = useState(false);

  function onRemoveClick() {
    setLoading(true);
    axios
      .delete(`/triggers/${trigger.id}/`)
      .then(() => {
        toast.success("Deleted!");
        setLoading(false);
        fetchData();
        onRemoveSuccess();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  return (
    <Flex gap="xs" align="center">
      <ActionIcon
        loading={loading}
        size="xs"
        variant="light"
        radius="xl"
        color="red"
        onClick={onRemoveClick}
      >
        <IconTrash size={14} />
      </ActionIcon>
      <Text size="sm">
        send <b>{trigger.email_template}</b> when <b>{trigger.option_label}</b>{" "}
        is selected
      </Text>
    </Flex>
  );
}
